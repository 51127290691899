import React, {useState, useEffect} from 'react';

import Card from '../../components/Card';
import Button from '../../components/Button';
import {StyledTextInput} from '../../components/Forms';
import CopyButton from '../../components/CopyButton';
import {BASE_URL} from '../../constants/config';
import {useAnalytics} from '../../hooks';
import {getPendingReferralRewards} from '../../api/api';

const Referral = () => {
  const {tracker} = useAnalytics();
  const [numRewards, setNumRewards] = useState(0);
  const [refCode, setRefCode] = useState(0);
  const refUrl = `${BASE_URL}/refer/${refCode}`;

  useEffect(() => {
    async function fetchRewards() {
      const {
        numRewards: resp,
        referralCode,
      } = await getPendingReferralRewards();

      setNumRewards(resp);
      setRefCode(referralCode);
    }

    fetchRewards();
  }, []);

  const onClickCopy = () => {
    tracker.dashboardClickCopyReferralCode();
  };

  return (
    <Card className="flex flex-col">
      <span className="text-lg font-semibold mb-1">
        Refer your friends 🎁
      </span>
      <span className="text-sm font-medium text-gray-500 mb-2">
        Share your 10% discount with four friends and get
        {' '}
        <span className="text-indigo">free lifetime access</span>
        {' '}
        to Exponent!
      </span>
      <div className="relative mb-6">
        <StyledTextInput
          value={refUrl}
          readOnly
          className="text-sm text-gray-500"
        />
        <span
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white font-bold text-indigo cursor-pointer"
          style={{boxShadow: '-20px 0 9px 1px white'}}
        >
          <CopyButton value={refUrl} onClick={onClickCopy} />
        </span>
      </div>
      <span>
        <Button outline href="/referrals?src=dashboard" className="text-sm px-4">
          {numRewards > 0 ? 'View rewards' : 'More details'}
        </Button>
      </span>
    </Card>
  );
};

export default Referral;

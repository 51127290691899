import React, {useState} from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

import {DuplicateIcon, SimpleCheckIcon} from './svg';

const CopyButton = ({value, onClick, ...props}) => {
  const [copied, setCopied] = useState(false);

  const onClickCopy = () => {
    navigator.clipboard.writeText(value);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
    onClick?.();
  };

  return copied ? (
    <OverlayTrigger placement="right" overlay={<Tooltip>Copied</Tooltip>}>
      <button type="button" onClick={onClickCopy} {...props}>
        <SimpleCheckIcon className="h-5 w-5 text-green-400" />
      </button>
    </OverlayTrigger>
  ) : (
    <OverlayTrigger placement="right" overlay={<Tooltip>Copy to clipboard</Tooltip>}>
      <button type="button" onClick={onClickCopy} {...props}>
        <DuplicateIcon className="h-5 w-5 text-gray-400 hover:text-gray-600" />
      </button>
    </OverlayTrigger>
  );
};

export default CopyButton;
